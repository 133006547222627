import {createBrowserRouter, Navigate} from "react-router-dom";
import App from "./App";
import React from "react";

import ProtectedRoute from "./components/ProtectedRoute";
import ErrorView from "./pages/ErrorView";

import AdminWrapper from "./pages/admin/Wrapper";
import MemberWrapper from "./pages/member/Wrapper";
import HydrantWrapper from "./pages/hydrant/Wrapper";

import AdminRoutes from "./routes/admin";
import HydrantRoutes from "./routes/hydrant";
import MemberRoutes from "./routes/member";

// --- Pages --------------------------------------------------------------------------------------
// General
const LoginView = React.lazy(() => import("./pages/LoginView"))
const SignUpView = React.lazy(() => import("./pages/SignUpView"))
const AppView = React.lazy(() => import("./pages/AppView"))
const SettingsView = React.lazy(() => import("./pages/SettingsView"))
// Organization
const OrgMainView = React.lazy(() => import("./pages/organization/MainView"))
const OrgCreateView = React.lazy(() => import("./pages/organization/CreateView"))


const appRoutes = [
  {
    errorElement: <ErrorView/>,
    children: [
      {
        index: true,
        element: (
            <React.Suspense>
              <OrgMainView/>
            </React.Suspense>
        )
      },
      {
        path: 'settings',
        element: (
            <React.Suspense>
              <SettingsView/>
            </React.Suspense>
        )
      },
      {
        path: 'mem',
        element: <MemberWrapper/>,
        children: MemberRoutes,
      },
      {
        path: 'hyd',
        element: <HydrantWrapper/>,
        children: HydrantRoutes,
      },
      {
        path: 'admin',
        element: <AdminWrapper />,
        children: AdminRoutes,
      }
    ]
  }
]

const router = createBrowserRouter([
  {
    element: <App/>,
    errorElement: <Navigate to="/login"/>,
    children: [
      {
        path: 'login',
        element: (
            <React.Suspense fallback={<></>}>
              <LoginView/>
            </React.Suspense>
        )
      },
      {
        path: 'sign-up',
        element: (
            <React.Suspense>
              <SignUpView/>
            </React.Suspense>
        )
      },
      {
        path: 'org',
        children: [
          {
            path: 'new',
            element: (
                <React.Suspense>
                  <OrgCreateView/>
                </React.Suspense>
            )
          }
        ]
      },
      {
        path: 'app',
        errorElement: <ErrorView/>,
        element: (
            <React.Suspense fallback={<></>}>
              <ProtectedRoute isAuthenticated>
                <AppView/>
              </ProtectedRoute>
            </React.Suspense>
        ),
        children: appRoutes
      },
    ]
  }
])

export default router
