import {createContext, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";

import Api from "../utils/api"

const AuthContext = createContext(null)

const loadTokens = () => {
  const authTokens = localStorage.getItem("authTokens")
  if (authTokens !== null) {
    return JSON.parse(authTokens)
  } else {
    return null
  }
}

const saveTokens = (tokens) => {
  localStorage.setItem("authTokens", JSON.stringify(tokens))
}

const AuthProvider = ({children}) => {
  const login = async (email, password) => {
    try {
      const response = await Api.post('/api/user/login',
          {
            email: email,
            password: password
          }
      )
      const data = await response.data
      saveTokens(data)
      setTokens(data)
    } catch (e) {
      console.error('Error', e)
      throw e.response
    }
  }
  const refresh = async () => {
    if (tokens !== null) {
      try {
        const response = await Api.post('/api/user/token/refresh', {
          refreshToken: tokens.refreshToken
        })
        const data = await response.data
        saveTokens(data)
        setTokens(data)
      } catch (e) {
        console.error('Error', e)
      }
    }
  }

  const logout = () => {
    saveTokens(null)
    setTokens(null)
  }

  useEffect(() => {
    if (loading) {
      refresh()
      setLoading(false)
    }
    const timeout = setInterval(refresh, 5 * 60 * 1000);
    return () => {
      clearInterval(timeout)
    }
  });

  const [tokens, setTokens] = useState(() => loadTokens())
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isSuperuser, setIsSuperuser] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (tokens === null) {
      setUser(null)
      setIsAdmin(false)
      setIsSuperuser(false)
    } else {
      const tokenContent = jwt_decode(tokens.accessToken)
      setUser(tokenContent.userinfo)
      setIsAdmin(tokenContent.isAdmin)
      setIsSuperuser(tokenContent.isSuperuser)
    }
  }, [tokens]);

  const contextValue = {
    // values
    tokens,
    user,
    isAdmin,
    isSuperuser,
    // actions
    login,
    refresh,
    logout
  }

  return (
      <AuthContext.Provider value={contextValue}>
        { loading ? null : children }
      </AuthContext.Provider>
  )
}

export default AuthContext

export {AuthProvider, loadTokens, saveTokens}

