import React from "react";

const MemberCreateView = React.lazy(() => import("../pages/member/CreateView"))
const MemberDetailView = React.lazy(() => import("../pages/member/DetailView"))
const MemberMainView = React.lazy(() => import("../pages/member/MainView"))
const MemberModifyView = React.lazy(() => import("../pages/member/ModifyView"))

const routes = [
  {
    path: 'home',
    element: (
        <React.Suspense>
          <MemberMainView/>
        </React.Suspense>
    )
  },
  {
    path: 'new',
    element: (
        <React.Suspense>
          <MemberCreateView/>
        </React.Suspense>
    )
  },
  {
    path: 'detail/:memberId',
    element: (
        <React.Suspense>
          <MemberDetailView/>
        </React.Suspense>
    )
  },
  {
    path: 'modify/:memberId',
    element: (
        <React.Suspense>
          <MemberModifyView/>
        </React.Suspense>
    )
  },
]

export default routes
