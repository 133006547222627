import axios from "axios";

import {loadTokens} from "../context/AuthContext";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(async config => {
  const tokens = loadTokens();

  if (tokens !== null) {
    config.headers['Authorization'] = `Bearer ${tokens.accessToken}`
  }
  config.headers['Accept'] = 'application/json'
  return config
})

export default axiosApiInstance
