import {Link as RouterLink, Outlet} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";


function Wrapper() {
    const {t} = useTranslation("hydrant")

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2, justifyContent: 'space-evenly', gap: '20%'}}>
                <Button
                    variant="contained"
                    color="inherit"
                    sx={{flexGrow: 1}}
                    component={RouterLink}
                    to="/app/hyd/home">
                    {t('showList')}
                </Button>
                <Button
                    variant="contained"
                    color="inherit"
                    sx={{flexGrow: 1}}
                    component={RouterLink}
                    to="/app/hyd/map">
                    {t('showMap')}
                </Button>
                <Button
                    variant="contained"
                    color="inherit"
                    sx={{flexGrow: 1}}
                    component={RouterLink}
                    to="/app/hyd/new">
                    {t('create')}
                </Button>
            </Box>
            <Outlet/>
        </Box>
    )
}

export default Wrapper;
