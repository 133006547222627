import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import {Navigate} from "react-router-dom";

function ProtectedRoute({children, isAuthenticated = true, isAdmin = false, isSuperuser = false}) {
  const {user} = useContext(AuthContext)

  const notAllowed = (isAuthenticated && user === null)
      || (isAdmin && user?.userinfo.isAdmin !== true)
      || (isSuperuser && user?.userinfo.isSuperuser !== true)

  if (notAllowed) {
    return (
        <Navigate to={"/login"} replace/>
    )
  }
  return children
}

export default ProtectedRoute
