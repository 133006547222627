import React from "react";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

import 'dayjs/locale/de';
import 'dayjs/locale/en';

import {AuthProvider} from "./context/AuthContext";
import {GeoProvider} from "./context/GeoContext";
import {OrganizationProvider} from "./context/OrganizationContext";
import defaultTheme from "./utils/theme";

import './App.css';

function App() {
  const {i18n} = useTranslation()

  return (
      <GeoProvider>
        <AuthProvider>
          <ThemeProvider theme={defaultTheme}>
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
              <OrganizationProvider>
                <Outlet/>
              </OrganizationProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </GeoProvider>
  );
}

export default App;
