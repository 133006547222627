import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend((lang, ns) => import(`../locales/${lang}/${ns}.json`)))
    .init({
      lng: "de",
      fallbackLng: "de",
      debug: true,
    })

export default i18n
