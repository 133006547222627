import {createContext, useContext, useEffect, useState} from "react";

import ApiClient from "../utils/api";
import AuthContext from "./AuthContext";

const OrganizationContext = createContext(null)

const loadOrgData = () => {
  const stringData = localStorage.getItem('organization')
  if (stringData === null || stringData === '') {
    return null
  }
  return JSON.parse(stringData)
}

const saveOrgData = (data) => {
  if (data === null) {
    localStorage.removeItem('organization')
  } else {
    localStorage.setItem('organization', JSON.stringify(data))
  }
}

const OrganizationProvider = ({children}) => {
  const [loading, setLoading] = useState(true)
  const [selectedOrg, setSelectedOrg] = useState(() => loadOrgData()?.selected)
  const [orgList, setOrgList] = useState(() => loadOrgData()?.list)
  const {user} = useContext(AuthContext)

  const refreshOrgList = async () => {
    try {
      const response = await ApiClient.get('/api/orgs');
      const data = await response.data;
      setOrgList(data)
    } catch (response) {
      console.error(response)
    }
  }

  const selectOrg = (id) => {
    if (id === null) {
      setSelectedOrg(null)
      return
    }
    const org = orgList.find((val) => {
      return val.id === id
    })
    setSelectedOrg(org)
  }

  useEffect(() => {
    if (loading && user !== null) {
      refreshOrgList().then(() => {
        setLoading(false)
      })
    }
  }, [loading, refreshOrgList]);

  useEffect(() => {

    saveOrgData({
      list: orgList,
      selected: selectedOrg
    })
  }, [orgList, selectedOrg]);

  const contextValue = {
    selectedOrg,
    orgList,
    refreshOrgList,
    selectOrg,
  }

  if (user === null) {
    return <>{children}</>
  }

  return (
      <OrganizationContext.Provider value={contextValue}>
        {loading ? null : children}
      </OrganizationContext.Provider>
  )
}

export default OrganizationContext;
export {OrganizationProvider, saveOrgData, loadOrgData};
