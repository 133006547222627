import React from "react";

const HydrantMainView = React.lazy(() => import("../pages/hydrant/MainView"))
const HydrantCreateView = React.lazy(() => import("../pages/hydrant/CreateView"))
const HydrantMapView = React.lazy(() => import("../pages/hydrant/MapView"))
const HydrantModifyView = React.lazy(() => import("../pages/hydrant/ModifyView"))

const routes = [
    {
        path: 'home',
        element: (
            <React.Suspense>
                <HydrantMainView/>
            </React.Suspense>
        )
    },
    {
        path: 'map',
        element: (
            <React.Suspense>
                <HydrantMapView/>
            </React.Suspense>
        )
    },
    {
        path: 'new',
        element: (
            <React.Suspense>
                <HydrantCreateView/>
            </React.Suspense>
        )
    },
    {
        path: 'modify/:hydrantId',
        element: (
            <React.Suspense>
                <HydrantModifyView/>
            </React.Suspense>
        )
    }
]

export default routes

