import React from "react";

const MainView = React.lazy(() => import('../pages/admin/MainView'))

const routes = [
  {
    path: 'home',
    element: (
        <React.Suspense>
          <MainView />
        </React.Suspense>
    )
  }
]

export default routes
