import {createContext, useState} from "react";


const GeoContext = createContext(null);


const GeoProvider = ({children}) => {
  const [geolocation, setGeolocation] = useState(null)
  const [locReqd, setLocReqd] = useState(false)
  const [locPermGiven, setLocPermGiven] = useState(false)

  const posSucc = (position) => {
    setGeolocation(position)
    setLocPermGiven(true)
  }

  const posErr = (err) => {
    if (err.code === 2) {
      setLocPermGiven(false)
    }
  }

  const refresh = () => {
    if (!navigator.geolocation) {
      return;
    }
    setLocReqd(true)
    navigator.geolocation.getCurrentPosition(posSucc, posErr)
  }

  const contextValue = {
    location: geolocation,
    locationRequested: locReqd,
    locationPermGiven: locPermGiven,
    refreshLocation: refresh,
  }

  return (
      <GeoContext.Provider value={contextValue}>
        {children}
      </GeoContext.Provider>
  )
}

export default GeoContext;

export {GeoProvider};

